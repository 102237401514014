<template>
    <div class="main__container main__padding column__center">
        <div class="imprint-box  ">
            <h2>FAQ – Fragen und Antworten</h2>

            <div class="row__start mt-40 mb-40">
                <a
                    href="https://admin.ausbildungschance-owl.de/exhibitor/registration"
                    class="button button--big2 mr-20"
                >
                    <!-- TODO: Link -->
                    <p class="text__white">
                        Als Unternehmen registrieren
                    </p>
                </a>
                <a
                    href="https://admin.ausbildungschance-owl.de"
                    class="button button--reverse--big"
                >
                    <!-- TODO: Link -->
                    <p class="text__primary">Unternehmens-Login</p>
                </a>
            </div>

            <collapse>
                <template #title
                    >Was ist beim Feld „Dein Einstieg“ zu beachten?
                </template>
                <template #content>
                    Bitte wählen Sie hier Ihre
                    Ausbildungsberufe/Einstiegsqualifizierung/Duales
                    Studium/Berufsfelderkundungstage/Praktika aus, die Sie
                    anbieten möchten. Die Berufe werden von der Software
                    automatisch den Berufsfeldern zugeordnet. Auf der
                    Website/App können die Bewerberinnen und Bewerber nach
                    Berufsfeldern und Angeboten filtern. So werden Sie als
                    Anbieter über die Karte gefunden. Sollten Ihre angebotenen
                    Berufe nicht in der Liste stehen, schreiben Sie uns eine
                    E-Mail (<a href="mailto:info@ausbildungschance-owl.de"
                        >info@ausbildungschance-owl.de</a
                    >). Wir erweitern dann zeitnah die Liste um Ihre
                    Berufsbezeichnungen.
                    <br />
                    <br />
                    Wie hängen die Felder „Kontakt für Terminbuchungen“ und
                    „Terminmöglichkeiten“ zusammen?<br />
                    Das Herzstück von „Ausbildungschance OWL“ ist die Funktion
                    der Terminbuchung über 15 Minuten pro Termin. Hier geben Sie
                    sowohl die Gesprächspartnerinnen und Gesprächspartner als
                    auch die Zeiträume vor.
                    <br />
                    <br />
                    Dazu ein Beispiel:<br />
                    Sie wählen an einem Tag beispielsweise den Zeitraum 14 Uhr
                    bis 16 Uhr für den Ausbildungsleiter Max Mustermann aus. In
                    diesen zwei Stunden stehen den Ausbildungsplatzsuchenden
                    somit 8 Termine á 15 Minuten zur Buchung bei Herrn
                    Mustermann zur Verfügung.
                    <br />
                    <br />
                    Wenn Sie eine weitere Ansprechpartnerin oder einen
                    Ansprechpartner einbinden, können in diesem Zeitfenster
                    insgesamt 16 Termine gebucht werden.
                    <br />
                    <br />
                    Bieten Sie gerne über den gesamten Zeitraum gleichmäßig
                    Terminfenster an. Dafür können Sie die Funktion „Zeitraum
                    hinzufügen“ gut nutzen. Wichtig bei der Erfassung der
                    Gesprächspartnerinnen und Gesprächspartner:<br />
                    Bitte hier nicht noch einmal die Hauptansprechperson
                    eintragen. Diese steht automatisch, für die Eintragung der
                    Terminmöglichkeiten zur Verfügung.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Wie hängen die Felder „Hauptansprechpartner“, „Weitere
                    Gesprächspartner“ und „Terminmöglichkeiten“ zusammen?
                </template>
                <template #content>
                    Das Herzstück von „Ausbildungschance OWL“ ist, neben Ihrem
                    individuellen Auftritt, die Funktion der Terminbuchung über
                    15 Minuten pro Termin. Hier haben Sie die Möglichkeit, Ihre
                    Gesprächspartnerinnen und Gesprächspartner und die
                    entsprechenden Zeiträume vorzugeben. Die Gesprächsdauer von
                    15 Minuten ist lediglich eine pauschale Voreinstellung, die
                    individuelle Gesprächsdauer ist natürlich Ihnen überlassen.
                    <br /><br />

                    Dazu ein Beispiel: <br />

                    Sie wählen an einem Tag beispielsweise den Zeitraum 14 Uhr
                    bis 16 Uhr für den Ausbildungsleiter Max Mustermann aus. In
                    diesen zwei Stunden stehen den Bewerberinnen und Bewerber
                    somit acht Termine á 15 Minuten zur Buchung bei Herrn
                    Mustermann zur Verfügung. <br /><br />

                    Wenn Sie eine weitere Ansprechpartnerin oder einen
                    Ansprechpartner einbinden, können in diesem Zeitfenster
                    insgesamt 16 Termine gebucht werden. <br /><br />

                    Bieten Sie gerne über den gesamten Zeitraum gleichmäßig
                    Terminfenster an. Dafür können Sie die Funktion „Zeitraum
                    hinzufügen" gut nutzen.<br />Bitte achten Sie darauf, dass
                    Sie zu den angegebenen Terminfenstern auch erreichbar sind.
                    Daher ist es ratsam, ggf. an mehreren Tagen kürzere
                    Terminslots anzubieten. <br /><br />
                    Wichtig bei der Erfassung der Gesprächspartnerinnen und
                    Gesprächspartner: <br />

                    Bitte hier nicht noch einmal die Hauptansprechperson
                    eintragen. Diese steht automatisch, für die Eintragung der
                    Terminmöglichkeiten zur Verfügung.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Wie funktioniert der Prozess „Buchung Termine –
                    Kontaktaufnahme – Gespräch“?
                </template>
                <template #content>
                    Die von Ihrem Unternehmen freigegebenen Zeiträume pro
                    Ansprechpartnerin oder Ansprechpartner werden als
                    15-Minuten-Termine über die Webseite/App auf dem
                    Unternehmensprofil unter dem Punkt „Termine vereinbaren" den
                    Bewerberinnen und Bewerber angeboten.
                    <br /><br />

                    Die Bewerberinnen und Bewerber wählen den Termin (Tag und
                    Uhrzeit), den Kontakt und die Art der Kontaktaufnahme
                    (Telefongespräch, Videogespräch, Chat) aus. Dann geben die
                    Bewerberinnen und Bewerber ihre E-Mail-Adresse an, die
                    ausschließlich zum Versand der Bestätigungs-E-Mail und einer
                    Erinnerungs-E-Mail genutzt wird. Bewerberinnen und Bewerber
                    können keine weiteren Daten eingeben. Die E-Mail-Adresse
                    wird ausschließlich intern benutzt und nicht weitergegeben
                    oder veröffentlicht. Terminbuchungen sind bis 15:00 Uhr am
                    Vortag möglich. <br />

                    Anschließend bekommen die jeweiligen Bewerberinnen und
                    Bewerber die Bestätigungs-E-Mail mit entweder<br />
                    - der Telefonnummer (bei Auswahl Telefon) der
                    Ansprechpartnerin oder des Ansprechpartners <br />oder<br />
                    - dem Link zum Aufbau einer Jitsi-Sitzung (bei Auswahl
                    „Videogespräch" oder „Chat"). Zudem werden die Zeit des
                    Gesprächs, der Name der Gesprächspartnerin und des
                    Gesprächspartners und ein erklärender Text eingefügt.
                    Weiterhin wird für die Bewerberinnen und Bewerber in der
                    E-Mail beschrieben, wie ein vereinbarter Termin wieder
                    gelöscht werden kann.<br />
                    Zeitgleich, also direkt nach der Terminbuchung durch die
                    Bewerberinnen und Bewerber, erhält der Ansprechpartner
                    ebenfalls eine E-Mail mit der Buchungsnummer der
                    Bewerberinnen und Bewerber und die zeitlichen Daten der
                    Buchung. <br /><br />

                    Eine Übersicht aller gebuchten Termine finden Sie unter dem
                    Punkt „Termine".

                    <br /><br />

                    Beide Seiten erhalten 30 Minuten vor dem Termin nochmals
                    eine Erinnerungs-E-Mail.
                    <br />
                    Die Bewerberinnen und Bewerber sind dann zum Zeitpunkt des
                    gebuchten Termins in der Pflicht, entweder bei dem
                    Ansprechpartner anzurufen (bei Auswahl „Telefongespräch")
                    oder den Link zur Jitsi-Sitzung zu öffnen (bei Auswahl oder
                    „Videogespräch" oder „Chat")
                    <br /><br />

                    Der Ansprechpartner Ihres Unternehmens muss zum vereinbarten
                    Termin telefonisch über die angegebene Telefonnummer zur
                    Verfügung zu stehen (bei Auswahl „Telefongespräch") oder zum
                    vereinbarten Termin den Link aus der E-Mail zu öffnen (bei
                    Auswahl „Videogespräch/Chat"). Die „Gespräche per Video und
                    Chat" funktionieren auf allen Endgeräten, die mit einem
                    Mikrofon und einer Kamera ausgestattet sind (Laptop,
                    Smartphone, Tablet, ...).
                    <br />
                    Die Videokonferenz-Software Jitsi liegt auf einem Server in
                    Deutschland. Der Link wird auf beiden Seiten - Unternehmen
                    und Bewerberinnen und Bewerber - im Browser geöffnet. Es
                    muss also keine Software im Vorfeld installiert werden. In
                    Jitsi ist es möglich lediglich per Chat zu kommunizieren
                    oder Ton und Video mit einzuschalten.
                    <br /><br />
                    Tipp: Fragen Sie zu Beginn per Chat oder Ton ob der oder die
                    Bewerberinnen und Bewerber ausschließlich chatten möchte,
                    das Mikrofon mit einschalten möchte oder sogar die Kamera
                    nutzen möchte. Ein "Herantasten" an die Varianten ist für
                    Bewerberinnen und Bewerber sicher angenehmer.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Wann kann eine Ausbildungsstelle wieder aus dem Angebot
                    herausgenommen werden/ Deaktivierung?
                </template>
                <template #content>
                    Sobald Sie eine angebotene Ausbildungsstelle besetzen
                    konnten, bitten wir Sie, diese einzeln löschen.
                </template>
            </collapse>
            <!-- <collapse>
				<template #title>Wann werden die Daten gelöscht? </template>
				<template #content>
					Sollten Sie Ihre kompletten Daten (Unternehmensprofil) löschen und
					nicht nur inaktiv setzen wollen, so setzen Sie bitte den Button
					<span class="text__secondary">„Profil löschen“</span> aktiv. Ihre
					Daten werden unwiderruflich aus der App sowie von der Website
					gelöscht. Sollten Sie nochmals an der „Ausbildungschance OWL“
					teilnehmen wollen, ist eine erneute Eingabe erforderlich.
				</template>
			</collapse> -->
            <collapse>
                <template #title
                    >Müssen Sie sich die App installieren?
                </template>
                <template #content>
                    Zur Nutzung von „Ausbildungschance OWL“ ist es nicht
                    zwingend erforderlich, die entsprechende App zu
                    installieren. Auch über die Website
                    www.ausbildungschance-owl.de können Sie ebenfalls alle
                    Funktionen anwenden.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Was muss beim Einbinden von Videos beachtet werden?
                </template>
                <template #content>
                    Hier können Sie Videos Ihres Unternehmens oder Ihrer
                    Institution verlinken. Die Videos müssen entweder auf
                    Youtube oder Vimeo stehen, andere Quellen funktionieren hier
                    nicht. <br />
                    Bewerberinnen und Bewerber wünschen sich einen kurzen
                    Einblick in den Beruf. Hier kommen auch mit dem Handy selbst
                    gedrehte Videos gut an.
                    <br />
                    Vielleicht können Sie ein Azubiprojekt dazu starten.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Was kann im Unternehmensprofil unter „Schon gewusst?“
                    eingetragen werden?
                </template>
                <template #content>
                    Hier können Sie Aufmerksamkeit erzeugen, beispielsweise
                    durch: <br />
                    - Hinweise auf besondere Veranstaltungen oder Termine wie
                    Tag der offenen Betriebstür, … <br />

                    - Teilnahme an Projekten zur Berufsorientierung wie SchuBS,
                    Bobby Car Solar Cup, … <br />

                    - Informationen zu Zusatzqualifikationen und Kursen wie
                    Sprachzertifikate, … <br />

                    - Inner- oder außerbetriebliche Prüfungsvorbereitungen und
                    Verbundausbildung <br />

                    - Besonderheiten zur Berufsschule, Uni, Fachhochschule
                    <br />

                    - Besondere Projekte für Azubis wie Auftaktwoche,
                    Projektwoche, Azubifiliale, … <br />

                    - Anreize für Azubis wie Azubiticket, Nutzung
                    Firmenfahrzeug, Honorierung Leistung, … <br />

                    - Auszeichnungen des Unternehmens <br />

                    - Besonderes Gehaltzahlungen und Zusatzleistungen wie 13.
                    Monatsgehalt, … <br />
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Was kann im Unternehmensprofil unter „So kannst Du Dich
                    bewerben“ eingetragen werden?
                </template>
                <template #content>
                    Für Bewerberinnen und Bewerber ist es wichtig auf einen
                    Blick zu erkennen, wie die Bewerbung aussehen soll und vor
                    allem, wie sie versandt werden soll:
                    <br />
                    Beispielsweise:
                    <br />
                    - Sende uns Deine Bewerbung per E-Mail an
                    personal@mustermann.de. Deine Bewerbung sollte ein
                    Anschreiben, Deinen Lebenslauf und Deine letzten beiden
                    Zeugnisse erhalten. Auch Bescheinigungen über bisherige
                    Tätigkeiten oder ehrenamtliches Engagement interessieren uns
                    sehr. Bitte führe Deine Unterlagen in einer PDF - Datei
                    zusammen, die maximal 5 MB groß ist.
                    <br />
                    oder
                    <br />
                    - Unser Bewerbungsprozess läuft ausschließlich online über
                    unser Bewerbungs-Portal.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Wie können die erfassten Daten gespeichert werden?
                </template>
                <template #content>
                    Ihre erfassten Daten sind direkt nach der Eingabe
                    gespeichert. Einen Button „Speichern" gibt es nicht. Sie
                    können sich oben rechts über Ihren Namen direkt ausloggen.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Wie kann das Passwort geändert werden?
                </template>
                <template #content>
                    Über das Menü links unter dem Punkt „Einstellungen". Bitte
                    einfach das neue Passwort eintippen, dann werden die Punkte
                    kurz grün, das neue Passwort ist direkt gespeichert. Eine
                    Bestätigung oder ein Abspeichern ist nicht vorgesehen.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Warum lässt sich im Videogespräch der Ton und die Kamera
                    nicht einschalten?
                </template>
                <template #content>
                    Das Videogespräch über Jitsi mit Nutzung der Video- und
                    Tonfunktion funktioniert nur mit einigen Browsern in
                    aktueller Version. Bei der Verwendung von Google Chrome,
                    Edge oder Safari funktioniert dies gut, bei anderen
                    Browseranbietern (z. B. Internet Explorer) eher nicht.

                    <br /><br />

                    Sollte die Nutzung der Video- und/oder Tonfunktion in Ihrem
                    Gespräch (von einem oder beiden Gesprächspartnern) nicht
                    möglich sein, so nutzen Sie doch alternativ nur die
                    Tonfunktion bzw. die Funktion des geschriebenen Chats.
                    Letzterer sollte in allen Browsern funktionieren.
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Können unterschiedliche Ausbildungsorte erfasst werden?
                </template>
                <template #content>
                    Ja, zuerst über den Punkt „Weitere Adressen verwalten" die
                    zusätzlichen Adressen erfassen und anschließend unter „Dein
                    Einstieg bei uns" zu jedem Berufsbild den Ort aus der Liste
                    wählen. <br />

                    Beispiel: <br />

                    Kaufmann (m/w/d) für Büromanagement --- Bielefeld <br />

                    Kaufmann (m/w/d) im Einzelhandel ---- Gütersloh <br />

                    Kaufmann (m/w/d) im Einzelhandel ---- Paderborn <br />
                </template>
            </collapse>
            <collapse>
                <template #title
                    >Welche Angebote für Bewerberinnen und Bewerber stellen Sie
                    zur Verfügung?
                </template>
                <template #content>
                    <span style="text-decoration: underline"
                        >Duale Ausbildung
                    </span>
                    <br />
                    Die duale Ausbildung bzw. betriebliche Ausbildung erfolgt an
                    zwei Lernorten, dem Ausbildungsunternehmen und der
                    Berufsschule.
                    <br /><br />

                    <span style="text-decoration: underline"
                        >Duales Studium</span
                    >
                    <br />
                    Das duale Studium kombiniert einen Hochschulbesuch mit
                    Praxiserfahrungen in einem Unternehmen oder Betrieb. Es gibt
                    verschiedene Modelle des dualen Studiums. Bestimmte Formen
                    ermöglichen zum Ende nicht nur einen Hochschulabschluss,
                    sondern zusätzlich einen Ausbildungsabschluss.

                    <br /><br />

                    <span style="text-decoration: underline"
                        >Betriebliche Einstiegsqualifizierung (EQ)</span
                    >
                    <br />
                    Die betriebliche Einstiegsqualifizierung (EQ) geht über 6 –
                    12 Monate. Die EQ dient als Brücke in die Berufsausbildung.
                    Betrieb und Jugendliche haben in einem betrieblichen
                    Praktikum bis zu 12 Monate Zeit, sich gegenseitig kennen zu
                    lernen und zu prüfen, ob eine anschließende Ausbildung in
                    diesem Beruf der richtige Weg ist.
                    <br /><br />

                    <span style="text-decoration: underline"
                        >Berufsfelderkundungstag (BFE)
                    </span>
                    <br />
                    Die Berufsfelderkundungstage bzw. Berufsfelderkundungen
                    (BFE) sind typische „Ein-Tages-Praktika“ und finden
                    vorrangig in Unternehmen statt. Sie ermöglichen praktische
                    Einblicke in die berufliche Arbeitswelt und werden in der
                    Regel in der Jahrgangsstufe 8 verbindlich durchgeführt.
                    Berufsfelderkundungen unterscheiden sich von
                    Betriebserkundungen bzw. Betriebsführungen, bei denen
                    Jugendliche vorrangig als "Zuschauerinnen und Zuschauer"
                    informiert werden. Bei Berufsfelderkundungen sind
                    Jugendliche aktiv beteiligt und können über praktische
                    Übungen und Ausprobieren sowie Beobachtungen und Gespräche
                    Erfahrungen sammeln.
                    <br /><br />

                    <span style="text-decoration: underline">Praktikum </span>
                    <br />
                    Über ein Praktikum lernen die Schülerinnen und Schüler die
                    Berufs- und Arbeitswelt anhand von definierten Aufgaben
                    unmittelbar kennen. Sie setzen sich über eine längere Zeit
                    praxisorientiert mit ihren eigenen Fähigkeiten und den
                    betrieblichen Anforderungen auseinander. In der Regel wird
                    ein Schülerbetriebspraktikum 2- bis 3-wöchig in der Sek I in
                    der Jahrgangsstufe 9 oder 10 sowie der Oberstufe verbindlich
                    durchgeführt.
                    <br /><br />
                </template>
            </collapse>
            <Footer class="w-100" />
        </div>
    </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Collapse from "../helper/Collapse.vue";
export default {
    components: { Footer, Collapse },
    name: "FAQ",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>
