<template>
    <div class="main__container main__padding column__center">
        <div class="imprint-box">
            <h2>INFORMATIONEN FÜR BEWERBERINNEN UND BEWERBER</h2>
            <div class="row__start mt-40">
                <a
                    class="button button--xl mr-20"
                    @click="
                        () => {
                            'Register' != $route.name
                                ? $router.push({ name: 'Register' })
                                : '';
                        }
                    "
                >
                    <p class="text__white">
                        Als Bewerberin oder Bewerber registrieren
                    </p>
                </a>
                <a
                    class="button button--reverse--big"
                    @click="
                        () => {
                            'Login' != $route.name
                                ? $router.push({ name: 'Login' })
                                : '';
                        }
                    "
                >
                    <p class="text__primary">Bewerberin- und Bewerber-Login</p>
                </a>
            </div>
            <br /><br />
            <div id="content">
                <div class="header-3">
                    Sicher Dir Deinen Ausbildungsplatz per App oder Website
                </div>
                <div class="header-3">Warum solltest Du mitmachen?</div>
                <br />
                <div>
                    Ausbildungschance OWL bietet Dir die Möglichkeit, den ersten
                    Kontakt zu Deinem potenziellen Arbeitgeber herzustellen.
                    Über die App oder Website hast Du die Möglichkeit, Termine
                    mit Ausbildungsunternehmen in Deiner Region zu vereinbaren.
                    In 15-minütigen Gesprächen kannst Du die Unternehmen von Dir
                    mit deinem sympathischen Auftreten am Telefon überzeugen.
                    <br />
                    <br />
                    Bist du Dir noch nicht sicher, welcher Beruf für Dich der
                    Richtige ist oder hast Du noch Fragen zum Bewerbungsprozess?
                    Dann steht Dir unser Beratungsteam zur Seite. Die
                    Kontaktdaten findest Du in der App oder auf der Website.
                    <br />
                    <br />
                    Über den <strong>Google Play Store</strong> oder
                    <strong>App Store</strong> kannst Du die neue App kostenfrei
                    herunterladen.
                    <br />
                    <br />
                    Wir wünschen Dir bei der Ausbildungsplatzsuche viel Erfolg!
                    <br />
                    <br />
                    Hol Dir die App kostenlos für Dein Smartphone oder Tablet
                    <br />
                    <div class="store__box">
                        <a
                            href="https://play.google.com/store/apps/details?id=de.apptitan.mobileapi.x63r7j&gl=DE"
                        >
                            <GooglePlaySvg />
                        </a>
                        <a
                            href="https://apps.apple.com/de/app/ausbildungschance-owl/id1515279423"
                        >
                            <AppStoreSvg />
                        </a>
                    </div>
                </div>
                <div class="header-3">Wie funktioniert die Website/App?</div>
                <br />
                <div>
                    Über die Suche kannst Du Unternehmen und Angebote (Duale
                    Ausbildungsplätze, Duale Studiengänge,
                    Einstiegsqualifizierung, Berufsfelderkundungstage, Praktika)
                    filtern und Dir Deine Auswahl anzeigen lassen.
                </div>

                <div>
                    <p>
                        Auf der Karte werden Dir die Unternehmen und im unteren
                        Bereich die ausführlichen Suchergebnisse gemäß Deiner
                        Filtereinstellungen angezeigt. Über die Suchergebnisse
                        kommst Du direkt auf die Profile der Unternehmen. Hier
                        kannst Du Dich näher informieren und direkt
                        Gesprächstermine mit Personen aus den Unternehmen
                        vereinbaren. Je nachdem, welche Gesprächsart das
                        Unternehmen freigegeben hat, kannst Du zwischen Telefon,
                        Chat oder Video auswählen. Für die Benachrichtigung
                        musst Du Deine E-Mail-Adresse angeben.
                    </p>
                </div>
                <br /><br />
                <div>
                    <p>
                        Beide Gesprächspersonen (Du und die Person des
                        Unternehmens) erhalten kurz vor dem Gesprächstermin eine
                        Erinnerung per E-Mail.
                        <br />
                        Terminbuchungen sind bis 15:00 Uhr am Vortag möglich.
                    </p>
                    <br />
                </div>
                <div>
                    <p>
                        <a
                            href="./Anleitung_Gesprächsbuchung_Ausbildungsplatzsuchende.pdf"
                            download="Anleitung_Gesprächsbuchung_Ausbildungsplatzsuchende.pdf"
                            ><strong>Hier</strong>
                        </a>
                        findest Du eine Anleitung zur Terminbuchung
                        <br />
                        <br />
                        In unserem
                        <a href="https://www.youtube.com/watch?v=3_oY8CcF0-0"
                            ><strong> Video </strong></a
                        >
                        geben wir dir Tipps für Online Bewerbungsgespräche.
                        <br />
                        <br />
                        <a href="https://www.youtube.com/watch?v=3_oY8CcF0-0">
                            <img src="../../public/img/images/anleitung.svg" />
                        </a>
                        <br />
                    </p>
                </div>
            </div>
            <br />
            <br />
            <div>
                <div class="header-1">
                    „Ich weiß noch nicht was ich machen möchte bzw. wo ich eine
                    Ausbildung machen kann“
                </div>
            </div>
            <div class="header-3">
                Ich habe Interesse an kaufmännischen, gewerblich-technischen und
                sonstigen Berufen und benötige Beratung!
            </div>
            <br />

            <div>
                <p>Wir unterstützen Dich auf dem Weg in Deine Ausbildung.</p>
                <p>
                    - Wir informieren Dich über die IHK-Ausbildungsberufe und
                    finden Berufe, die zu Deinen Stärken und Interessen passen.
                </p>
                <p>
                    - Dein Wunschberuf ist leider nicht realistisch? Wir finden
                    gemeinsam Alternativen.
                </p>
                <p>
                    - Du bist unsicher, ob deine Bewerbungsunterlagen gut sind –
                    wir sichten und optimieren diese gemeinsam mit Dir unter
                    einem „Blick aus der Wirtschaft“.
                </p>
                <p>- Wir stellen den Kontakt zu passenden Unternehmen her.</p>
                <br />
                <p>
                    Nach einem persönlichen Beratungsgespräch gleichen wir die
                    Anforderungen und Kompetenzen von Dir mit den Anforderungen
                    der Unternehmen ab.
                </p>
                <p>
                    Passen die Profile zueinander wird der Kontakt zwischen Dir
                    und dem Ausbildungsbetrieb hergestellt.
                </p>
                <br />
                <p>
                    Melde dich gerne für ein telefonisches Beratungsgespräch
                    bei.
                </p>
            </div>
            <div class="row__start mt-20">
                <div class="column__start">
                    Stephanie Wiedey <br />
                    <a
                        href="mailto:passgenau@ostwestfalen.ihk.de"
                        style="color: inherit"
                        ><MailFilledSvg class="mr-5" />
                        passgenau@ostwestfalen.ihk.de</a
                    >
                    <a href="tel:0521554143" style="color: inherit"
                        ><Phone2Svg class="mr-10" />0521 / 554 - 143</a
                    >
                </div>
            </div>
            <br />
            <div class="row__start mt-20">
                <div class="column__start">
                    Sebastian Prange <br />
                    <a
                        href="mailto:prange@detmold.ihk.de"
                        style="color: inherit"
                        ><MailFilledSvg class="mr-5" /> prange@detmold.ihk.de</a
                    >
                    <a href="tel:05231 760 - 178 " style="color: inherit"
                        ><Phone2Svg class="mr-10" />05231 760 - 178
                    </a>
                </div>
            </div>
            <br />
            <br />
            <div>
                <p>
                    Das Projekt „Passgenaue Besetzung – Unterstützung von KMU
                    bei der passgenauen Besetzung von Ausbildungsplätzen sowie
                    bei der Integration von ausländischen Fachkräften“ wird
                    durch das Bundesministerium für Wirtschaft und Energie und
                    den Europäischen Sozialfonds gefördert.
                </p>
            </div>
            <div>
                <div class="header-3">
                    Ich habe Interesse an Berufen aus dem Handwerk und benötige
                    Beratung
                </div>
                <br />
                <p>
                    Du bist Schülerin oder Schüler einer allgemeinbildenden
                    Schule oder einer internationalen Förderklasse am
                    Berufskolleg und hast Fragen rund um die duale Ausbildung im
                    Handwerk?
                </p>
                <br />
                <p>
                    Im Einzelgespräch informieren wir Dich individuell zu den
                    Möglichkeiten in den verschiedenen Branchen und sind bei
                    Bedarf gerne behilflich bei der Suche nach einem geeigneten
                    Praktikums- oder Ausbildungsplatz.
                </p>
                <br />
                <p>
                    Vielleicht hast Du noch keine Idee oder Vorstellung, was
                    nach der Schule alles geht. Erkundige Dich doch einfach, was
                    im Handwerk alles möglich ist. Es gibt rund 130
                    Ausbildungsberufe im Handwerk. Wir wetten, dass Du uns
                    maximal 10 nennen kannst. Wenn Du wissen möchtest, was es
                    mit den übrigen 120 Berufen auf sich hat oder aber auch
                    schon weißt, in welchem Ausbildungsberuf Du Dich siehst,
                    melde Dich einfach bei uns. Wir informieren dich gerne und
                    unterstützen Dich auf deinem Weg ins Praktikum oder in die
                    Ausbildung.
                </p>
            </div>
            <div class="row__start mt-20">
                <div class="column__start">
                    Jürgen Altemöller <br />
                    <a
                        href="mailto:Juergen.Altemoeller@hwk-owl.de"
                        style="color: inherit"
                        ><MailFilledSvg class="mr-5" />
                        Juergen.Altemoeller@hwk-owl.de</a
                    >
                    <a href="tel:05215608343" style="color: inherit"
                        ><Phone2Svg class="mr-10" />0521 / 5608 - 343</a
                    >
                </div>
            </div>
            <div class="row__start mt-20">
                <div class="column__start">
                    Tuba Kantis <br />
                    <a
                        href="mailto:Tuba.Kantis@hwk-owl.de"
                        style="color: inherit"
                        ><MailFilledSvg class="mr-5" />
                        Tuba.Kantis@hwk-owl.de</a
                    >
                    <a href="tel:05215608342" style="color: inherit"
                        ><Phone2Svg class="mr-10" />0521 / 5608 - 342</a
                    >
                </div>
            </div>
            <div class="row__start mt-20">
                <div class="column__start">
                    Hildegard Kuckuk <br />
                    <a
                        href="mailto:Hildegard.Kuckuk@hwk-owl.de"
                        style="color: inherit"
                        ><MailFilledSvg class="mr-5" />
                        Hildegard.Kuckuk@hwk-owl.de</a
                    >
                    <a href="tel:05215608324" style="color: inherit"
                        ><Phone2Svg class="mr-10" />0521 / 5608 - 324</a
                    >
                </div>
            </div>
            <br /><br />
            <div class="row__start mt-20">
                <!-- <router-link
					:to="{ name: 'InstructionInterview' }"
					class="button button--reverse button--big"
				> -->
                <a
                    class="text__primary button button--reverse button--big"
                    href="./Tipps-und-Tricks.pdf"
                    target="_blank"
                    >Download Tipps &amp; Tricks</a
                >
                <!-- </router-link> -->
            </div>
            <Footer class="w-100" />
        </div>
    </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
    components: { Footer },
    name: "ForStudents",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>

<style scoped>
.imprint-box .header-3 {
    margin-bottom: 0;
}
</style>
