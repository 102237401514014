<template>
	<div class="main__container main__padding column__center">
		<div class="imprint-box">
			<h2>Teilnehmende Unternehmen</h2>
			<div>
				<div class="header-3">
					Die nachfolgenden Auftritte der Unternehmen wurden
					individuell durch diese erstellt und sind kein Eigentum der
					Ausbildungschance OWL.
				</div>
				<br /><br />
			</div>
			<div
				class="searchbox row__start"
				style="margin-bottom:50px;width:70vw;max-width:1400px;"
			>
				<input
					type="text"
					v-model="searchInput"
					placeholder="Freitextsuche"
					@change="$emit('filterChanged')"
				/>
				<SearchSvg />
			</div>
			<div class="companyList">
				<div v-for="company in filteredCompanies" :key="company.id">
					<company-container :company="company" />
				</div>
			</div>

			<Footer class="w-100" />
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import CompanyContainer from "./container/CompanyContainer";
	export default {
		components: { CompanyContainer },
		name: "ParticipatingCompanies",
		data() {
			return {
				searchInput: "",
			};
		},
		methods: {
			...mapActions(["getAvailableCompanies"]),
		},
		mounted() {
			this.getAvailableCompanies({});
		},
		computed: {
			...mapGetters(["availableCompanies"]),
			filteredCompanies() {
				var companies = this.availableCompanies.filter((company) => {
					if (
						company.name
							.toLowerCase()
							.indexOf(this.searchInput.toLowerCase()) > -1
					) {
						return true;
					}
					return false;
				});
				return companies;
			},
		},
	};
</script>

<style scoped>
	.companyList {
		display: flex;
		flex-wrap: wrap;
	}
</style>
