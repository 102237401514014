<template>
    <div class="main__container main__padding column__center">
        <div class="imprint-box">
            <h2>INFORMATIONEN FÜR AUSBILDUNGSUNTERNEHMEN</h2>
            <div class="row__start mt-40 mb-40">
                <a
                    href="https://admin.ausbildungschance-owl.de/exhibitor/registration"
                    class="button button--big2 mr-20"
                >
                    <!-- TODO: Link -->
                    <p class="text__white">Als Unternehmen registrieren</p>
                </a>
                <a
                    href="https://admin.ausbildungschance-owl.de"
                    class="button button--reverse--big"
                >
                    <!-- TODO: Link -->
                    <p class="text__primary">Unternehmens-Login</p>
                </a>
            </div>
            <br />
            <div class="header-3">Warum sollten Sie mitmachen?</div>
            <div>
                Die App „<b>Ausbildungschance OWL</b>“ sowie die gleichnamige
                Website bieten Ihnen die Möglichkeit, den ersten Kontakt zu
                potenziellen Bewerberinnen und Bewerbern herzustellen. Dazu
                buchen die Ausbildungsplatzsuchenden über die App bzw. die
                Website einen telefonischen Termin oder ein Videogespräch bei
                der Ansprechpartnerin oder dem Ansprechpartner Ihres
                Unternehmens. Ein integrierter Chat kann zudem für die erste
                Kontaktaufnahme verwendet werden.
                <br />
                <br />
                <p class="text__secondary">
                    <b>Dieses Angebot ist für Sie kostenfrei!</b>
                </p>
                <br />
            </div>
            <div class="header-3">Wie machen Sie mit?</div>
            <div>
                Sie können sich in wenigen Schritten über das Feld
                „Registrierung“ anmelden. <br /><br />
                1. Registrierung: Nach der Registrierung wird eine erste E-Mail
                zur Verifizierung versandt.
                <br /><br />
                2. Freigabe: Nach der Freigabe der Administratoren erhalten Sie
                eine E-Mail mit Ihren Zugangsdaten.<br /><br />
                3. Bearbeitung des Unternehmensprofils: Über ein Online-Formular
                können die Informationen einfach erfasst werden.<br /><br />
                In unserer
                <a
                    href="./Anleitung_Unternehmensprofil_erstellen.pdf"
                    style="color: #003882"
                    target="_blank"
                    >Anleitung</a
                >
                finden Sie alle Schritte zur Profilerstellung.<br />
            </div>

            <div class="header-1">
                Wünschen Sie weitere Unterstützung bei der Suche nach geeigneten
                Azubis?
            </div>
            <div class="header-3">Unterstützung für IHK-Unternehmen (KMU)</div>
            <div>
                Im Rahmen des Projektes Passgenaue Besetzung unterstützen wir
                Sie bei der Suche nach geeigneten Auszubildenden. In einem
                ersten<br />
                Beratungsgespräch identifizieren wir Ihren individuellen
                Unterstützungsbedarf. <br /><br />
                - Gemeinsam erarbeiten wir ein Anforderungsprofil <br />
                - Wir sichten die eingehenden Bewerbungen und treffen für Sie
                eine Vorauswahl <br />
                - Geeignete Kandidatinnen und Kandidaten leiten wir an Sie
                weiter
                <br />
                - Wir beraten Sie zur Integration ausländischer Fachkräfte sowie
                zum Aufbau einer Willkommenskultur und Akquise ausländischer
                Auszubildender und Fachkräfte <br /><br />
                Diese Unterstützung bei der Nachwuchsgewinnung ist für kleine
                und mittlere Unternehmen kostenfrei. Das Programm wird durch das
                Bundesministerium für Wirtschaft und Energie und den
                Europäischen Sozialfonds gefördert.<br /><br />
                Sprechen Sie uns gerne an!
            </div>

            <div class="row__start mt-20">
                <div class="column__start">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld<br />
                    Stephanie Wiedey <br />
                    <a
                        href="mailto:passgenau@ostwestfalen.ihk.de"
                        class="logo--svg"
                        ><MailFilledSvg class="mr-5" />
                        passgenau@ostwestfalen.ihk.de</a
                    >
                    <a href="tel:0521554143" class="logo--svg">
                        <Phone2Svg class="mr-10" />0521 554 - 143</a
                    >
                    <br />
                </div>
            </div>

            <div class="row__start">
                <div class="column__start">
                    Industrie- und Handelskammer Lippe zu Detmold <br />
                    Sebastian Prange <br />
                    <a href="mailto:prange@detmold.ihk.de" class="logo--svg"
                        ><MailFilledSvg class="mr-5" /> prange@detmold.ihk.de</a
                    >
                    <a href="tel:05231760178" class="logo--svg">
                        <Phone2Svg class="mr-10" />05231 760 - 178</a
                    >
                    <br />
                </div>
            </div>
            <div class="header-3">
                Benötigen Sie Tipps zur Nutzung der Website/App oder bei der
                Erstellung Ihres Profils?
            </div>
            <div>
                In regelmäßigen Abständen bieten wir Einführungsveranstaltungen
                für Unternehmen an.<br />
                Wir werden Sie dazu per E-Mail informieren. <br />
                <br />
                Sie haben weitere Fragen? Sprechen Sie uns gerne an. <br />
                <br />
                Ihre regionalen Ansprechpartnerinnen und Ansprechpartner sind:
                <br />
                <br />
            </div>

            <!-- Stadt -->
            <div class="header-3">Stadt Bielefeld</div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld (IHK)
                    <br />
                    Bodo Venker,
                    <a href="tel:0521554268" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 268
                    </a>
                    <a href="mailto:b.venker@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        b.venker@ostwestfalen.ihk.de
                    </a>
                    <br />
                    Attila Sepsi,
                    <a href="tel:0521554262" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 262
                    </a>
                    <a href="mailto:a.sepsi@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        a.sepsi@ostwestfalen.ihk.de
                    </a>
                    <br />
                    <br />
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Andreas Heipieper,
                    <a href="tel:05215608329" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 329
                    </a>
                    <a href="mailto:andreas.heipieper@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        andreas.heipieper@hwk-owl.de
                    </a>
                </div>
            </div>

            <!-- Stadt -->
            <div class="header-3" style="margin-top: 40px">Kreis Gütersloh</div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld (IHK)
                    <br />
                    Michael Kaiser,
                    <a href="tel:0521554244" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 244
                    </a>
                    <a href="mailto:m.kaiser@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        m.kaiser@ostwestfalen.ihk.de
                    </a>
                    <br />
                    Ann-Kristin Ulas,
                    <a href="tel:0521554141" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 141
                    </a>
                    <a href="mailto:ak.ulas@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        ak.ulas@ostwestfalen.ihk.de
                    </a>
                    <br />
                    <br />
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Dirk Kucharewa,
                    <a href="tel:05215608313" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 313
                    </a>
                    <a href="mailto:dirk.kucharewa@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        dirk.kucharewa@hwk-owl.de
                    </a>
                </div>
            </div>

            <!-- Stadt -->
            <div class="header-3" style="margin-top: 40px">Kreis Herford</div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld (IHK)
                    <br />
                    Björn Kelle,
                    <a href="tel:0521554257" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 257
                    </a>
                    <a href="mailto:b.kelle@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        b.kelle@ostwestfalen.ihk.de
                    </a>
                    <br />
                    Uwe Gößling,
                    <a href="tel:0521554264" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 264
                    </a>
                    <a href="mailto:u.goessling@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        u.goessling@ostwestfalen.ihk.de
                    </a>
                    <br />
                    <br />
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Siegfried Morawe,
                    <a href="tel:05215608314" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 314
                    </a>
                    <a href="mailto:siegfried.morawe@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        siegfried.morawe@hwk-owl.de
                    </a>
                </div>
            </div>

            <!-- Stadt -->
            <div class="header-3" style="margin-top: 40px">
                Kreis Minden-Lübbecke
            </div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld (IHK)
                    <br />
                    Maik Scholz-Gutknecht,
                    <a href="tel:0521554245" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 245
                    </a>
                    <a href="mailto:m.scholz-gutknecht@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        m.scholz-gutknecht@ostwestfalen.ihk.de
                    </a>
                    <br />
                    Şengül Budak,
                    <a href="tel:0521554163" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 554 163
                    </a>
                    <a href="mailto:s.budak@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        s.budak@ostwestfalen.ihk.de
                    </a>
                    <br />
                    <br />
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Jürgen Altemöller,
                    <a href="tel:05215608343" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 343
                    </a>
                    <a href="mailto:juergen.altemoeller@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        juergen.altemoeller@hwk-owl.de
                    </a>
                </div>
            </div>

            <!-- Stadt -->
            <div class="header-3" style="margin-top: 40px">Kreis Paderborn</div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld (IHK)
                    <br />
                    Monika Grundke,
                    <a href="tel:052511559113" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 1559113
                    </a>
                    <a href="mailto:m.grundke@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        m.grundke@ostwestfalen.ihk.de
                    </a>
                    <br />
                    Kerstin Meister,
                    <a href="tel:05251155925" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 155925
                    </a>
                    <a href="mailto:k.meister@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        k.meister@ostwestfalen.ihk.de
                    </a>
                    <br />
                    <br />
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Tuba Kantis,
                    <a href="tel:05215608342" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 342
                    </a>
                    <a href="mailto:tuba.kantis@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        tuba.kantis@hwk-owl.de
                    </a>
                </div>
            </div>

            <!-- Stadt -->
            <div class="header-3" style="margin-top: 40px">Kreis Höxter</div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Industrie- und Handelskammer Ostwestfalen zu Bielefeld (IHK)
                    <br />
                    Monika Grundke,
                    <a href="tel:052511559113" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 1559113
                    </a>
                    <a href="mailto:m.grundke@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        m.grundke@ostwestfalen.ihk.de
                    </a>
                    <br />
                    Kerstin Meister,
                    <a href="tel:05251155925" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 155925
                    </a>
                    <a href="mailto:k.meister@ostwestfalen.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        k.meister@ostwestfalen.ihk.de
                    </a>
                    <br />
                    <br />
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Roman Ollech,
                    <a href="tel:05215608312" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 312
                    </a>
                    <a href="mailto:roman.ollech@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        roman.ollech@hwk-owl.de
                    </a>
                </div>
            </div>

            <!-- Stadt -->
            <div class="header-3" style="margin-top: 40px">Kreis Lippe</div>
            <div class="row__start mt-20">
                <div class="column__start" style="display: block">
                    Handwerkskammer Ostwestfalen-Lippe zu Bielefeld (HWK)
                    <br />
                    Hildegard Kuckuk,
                    <a href="tel:05215608324" class="mr-5"
                        ><Phone2Svg class="mr-5" />0521 5608 324
                    </a>
                    <a href="mailto:hildegard.kuckuk@hwk-owl.de"
                        ><MailFilledSvg class="mr-5" />
                        hildegard.kuckuk@hwk-owl.de
                    </a>
                    <br />
                    <br />
                    Industrie- und Handelskammer Lippe zu Detmold
                    <br />
                    Sebastian Prange,
                    <a href="tel:05231760178" class="mr-5"
                        ><Phone2Svg class="mr-5" />05231 7601 78
                    </a>
                    <a href="mailto:prange@detmold.ihk.de"
                        ><MailFilledSvg class="mr-5" />
                        prange@detmold.ihk.de
                    </a>
                </div>
            </div>
            <br /><br />
            <div class="row__start mt-20">
                <router-link
                    :to="{ name: 'FAQ' }"
                    class="button button--reverse button--big mr-20"
                >
                    <p class="text__primary">FAQ für Unternehmen</p>
                </router-link>
            </div>
            <br /><br />

            <Footer class="w-100" />
        </div>
    </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
    components: { Footer },
    name: "ForCompanies",
    data() {
        return {};
    },
    methods: {},
    created() {},
    computed: {},
};
</script>

<style scoped>
a {
    color: inherit;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    img {
        width: 93vw;
    }
}
</style>
